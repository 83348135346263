export type Project = {
    name: string;
    link: string;
    description: string;
    tools?: string;
    image_url: string;
    element?: JSX.Element;
}

export const projects = [
    {
        name: 'Luccid.ai',
        // link: 'https://app.luccid.ai'
        link: 'https://www.linkedin.com/posts/finnianj_react-nextjs-luccid-activity-7213889886242648064-pSsd?utm_source=share&utm_medium=member_desktop',
        description: 'A SaaS product for architects, including an AI chatbot with specialised architecture knowledge, as well a detailed project management system.',
        tools: 'React, Next.js, Typescript, TailwindCSS, Firebase, Google Cloud, Llamaindex, Python',
        image_url: '/images/luccid.png'
    },
    {
        name: 'Visual Snow Simulator',
        link: 'https://visualsnowsimulator.com',
        description: 'A tool for simulating the visual effects of Visual Snow Syndrome (VSS)',
        tools: 'React, Next.js, Typescript, TailwindCSS, React-three-fiber, Three.js',
        image_url: '/images/vss.png'
    },
    {
        name: 'Invoice Dashboard',
        link: 'https://nextjs-dashboard-ruddy-beta-13.vercel.app/',
        description: 'A demo dashboard for managing invoices',
        tools: 'React, Next.js, Typescript, TailwindCSS, Postgres, Prisma, NextAuth',
        image_url: '/images/invoice-dashboard.png'
    },
    {
        name: 'Finntech',
        link: 'https://finntech-two.vercel.app/',
        description: 'A demo fintech website, including a blog and business guide.',
        tools: 'React, Next.js, CSS Modules',
        image_url: '/images/finntech.png'
    },
    {
        name: 'Data Visualisations',
        link: 'https://codepen.io/finnianj/pen/xxaOPeQ',
        description: 'Mapping and visualising datasets with D3.js',
        image_url: '/images/data-visualisations.png'
    },
    {
        name: 'Android Birdwatching App',
        link: 'https://youtu.be/I4G2Trdc2hg',
        description: 'A simple Android app for birdwatchers!',
        tools: 'Expo, React Native, Nativewind, Typescript',
        image_url: '/images/avian-explorer.jpg'
    },
    {
        name: '3D World',
        element: <span className='text-sm'>{'(old portfolio)'}</span>,
        link: 'https://portfolio-rouge-tau-98.vercel.app/',
        description: 'A playful 3D world with interactive elements',
        tools: 'Vite, Three.js, van. Javascript, CSS & HTML',
        image_url: '/images/3d-world.png'
    },
    // {
    //     name: 'Wyvern Art Fund',
    //     link: 'https://wyv.art'
    // }
]

export const authorInfo = {
    name: 'Finn Charlton-Jones',
    description: 'Hello! 👋  I\'m Finn, a Fullstack Developer with a passion for creating beautiful, functional websites and 3D experiences. 🪄 I\'m always looking for new opportunities to learn and grow. I also love hiking and playng the drums! ☘️',
    image_url: '/images/me.jpeg?v=2',
    tools: 'Specialised in: React/React Native, Next.js, Typescript, Three.js',
}