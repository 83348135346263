type AuthorInfoProps = {
    authorInfo: any;
    isMobile: boolean;
    hovered: string | null;
    handleMouseEnter: (name: string) => void;
    handleMouseLeave: () => void;
    handleProjectClick: (e: React.MouseEvent, name: string) => void;
    setHovered: React.Dispatch<React.SetStateAction<string | null>>;
}

export const AuthorInfo = ({ authorInfo, isMobile, hovered, handleMouseEnter, handleMouseLeave, handleProjectClick, setHovered }: AuthorInfoProps) => {

    return (
        <>
            <button 
                onMouseEnter={() => handleMouseEnter(authorInfo.name)}
                onMouseLeave={handleMouseLeave}
                className={`hover:opacity-70 cursor-pointer text-base text-white opacity-40`}
                onClick={(e) => handleProjectClick(e, authorInfo.name)}
                onBlur={() => setHovered(null)}
            >
                {authorInfo.name}
            </button>
            <div className={`${hovered === authorInfo.name ? 'scale-100' : 'scale-0'} transition-all absolute z-20 ${isMobile ? 'origin-center -top-20' : ' top-0 origin-top-left left-[18rem] bg-opacity-70'}
                w-64 p-4 rounded-lg flex flex-col space-y-4 justify-center items-start 
                border border-gray-700 bg-indigo-900`
                }
            >
                {/* Image */}
                <img 
                    src={authorInfo.image_url} 
                    alt={'A picture of the author'} 
                    className='w-64 h-44 rounded-lg object-cover'
                />
                <p className='text-sm text-white font-light'>{authorInfo.description}</p>
                <p className='text-xs text-gray-300 font-medium'>{authorInfo.tools}</p>
            </div>
        </>
    )
}