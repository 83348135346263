export function getDesaturatedColor(hexColor: string, factor = 0.6) {
    // Remove the '#' if it's included
    hexColor = hexColor.replace('#', '');

    // Convert to RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate desaturated RGB values
    const newR = r + (255 - r) * factor;
    const newG = g + (255 - g) * factor;
    const newB = b + (255 - b) * factor;

    // Clamp values to ensure they are within 0-255 range
    const clampedR = Math.min(Math.floor(newR), 255);
    const clampedG = Math.min(Math.floor(newG), 255);
    const clampedB = Math.min(Math.floor(newB), 255);

    // Convert back to hexadecimal
    const desaturatedHexColor = `#${(clampedR < 16 ? '0' : '') + clampedR.toString(16)}${(clampedG < 16 ? '0' : '') + clampedG.toString(16)}${(clampedB < 16 ? '0' : '') + clampedB.toString(16)}`;

    return desaturatedHexColor;
}

export function blendColors(hex1: string, hex2: string, ratio = 0.5) {
    // Function to convert a hex color to its RGB components
    const hexToRgb = (hex: string) => {
      hex = hex.replace('#', ''); // Remove the hash symbol if present
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      return { r, g, b };
    };
  
    // Function to convert RGB components back to a hex color
    const rgbToHex = ({ r, g, b } : { r: number, g: number, b: number }) => {
      const toHex = (component: number) => component.toString(16).padStart(2, '0');
      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    };
  
    // Convert the hex colors to RGB components
    const rgb1 = hexToRgb(hex1);
    const rgb2 = hexToRgb(hex2);
  
    // Blend the RGB components
    const blendedRgb = {
      r: Math.round(rgb1.r * (1 - ratio) + rgb2.r * ratio),
      g: Math.round(rgb1.g * (1 - ratio) + rgb2.g * ratio),
      b: Math.round(rgb1.b * (1 - ratio) + rgb2.b * ratio),
    };
  
    // Convert the blended RGB components back to a hex color
    return rgbToHex(blendedRgb);
  }
  
  