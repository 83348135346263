import React from 'react';
import { Project } from './projects-data';

type ProjectInfoProps = {
    project: Project;
    fontColor: string;
    desaturatedHexColor: string;
    isMobile: boolean;
    index: number;
    hovered: string | null;
    handleMouseEnter: (name: string) => void;
    handleMouseLeave: () => void;
    handleProjectClick: (e: React.MouseEvent, name: string) => void;
    setHovered: React.Dispatch<React.SetStateAction<string | null>>;
}

export const ProjectInfo = ({ project, fontColor, desaturatedHexColor, isMobile, index, hovered, handleMouseEnter, handleMouseLeave, handleProjectClick, setHovered }: ProjectInfoProps) => {

    return (
        <div className="relative w-72 flex justify-center">
            <a
                href={project.link}
                target='_blank'
                key={index}
                className="transition-all cursor-pointer opacity-80"
                style={{ color: hovered === project.name ? 'gold' : 'inherit', opacity: hovered === project.name ? 1 : 0.8 }}
                // style={{ color: hovered === project.name ? desaturatedHexColor : 'inherit' }}
                onMouseEnter={() => handleMouseEnter(project.name)}
                onMouseLeave={handleMouseLeave}
                onClick={(e) => handleProjectClick(e, project.name)}
                onBlur={() => setHovered(null)}
            >
                {project.name} {project.element}
            </a>
            <div className={`${hovered === project.name ? 'scale-100' : 'scale-0'} transition-all absolute z-20 ${isMobile ? 'origin-center -top-20' : ' top-0 origin-top-left left-[18rem] bg-opacity-70'}
                w-64 p-4 rounded-lg flex flex-col space-y-4 justify-center items-start 
                border border-gray-700 bg-indigo-900`
                }
                // style={{ 
                //     backgroundImage: `linear-gradient(to bottom right, rgba(0, 0, 0, 0.5) 40%, ${fontColor} 100%)`,
                // }}
            >
                {/* Image */}
                <img 
                    src={`${project.image_url}?v=4`} 
                    alt={project.name} 
                    className='max-w-56 rounded-lg object-cover'
                />
                <p className='text-sm text-white font-light'>{project.description}</p>
                <em 
                    className={`text-xs text-gray-300 font-medium`}
                    // style={{ color: desaturatedHexColor }}
                >
                    {project.tools || ''}
                </em>
                {isMobile && (
                    <button className='rounded-lg py-1 px-3 text-xs border' onClick={() => {
                        window.open(project.link, '_blank');
                    }}>
                        Open
                    </button>   
                )}
            </div>
        </div>
    )
}