import { FaSpinner } from "react-icons/fa"

export const Fallback = () => {

    return (
        <div className="w-screen h-screen bg-black flex flex-col space-y-4 justify-center items-center">
            <FaSpinner className="text-white text-5xl animate-spin" />
            <h1 className="text-white josefin-sans text-4xl">Loading an awesome 3D scene!</h1>
            <h1 className="text-white josefin-sans text-2xl">This might take a moment...</h1>
        </div>
    )
}