import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { MdEmail, MdOutlineSwitchAccessShortcut } from "react-icons/md";

export const IconRow = ({ showEmail, handleEmailClick }: { showEmail: boolean, handleEmailClick: any }) => {
    return (
        <>
        {/* Github and Linkedin */}
        <div className="flex space-x-4">
                <a 
                    href="https://github.com/finnianj" 
                    target="_blank" 
                >
                    <FaGithub className="w-6 h-6 mr-1 text-white hover:opacity-70 opacity-40 transition-all cursor-pointer" />
                </a>
                <a 
                    href="https://www.linkedin.com/in/finnianj/" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <FaLinkedin className="w-6 h-6 mr-1 text-white hover:opacity-70 opacity-40  transition-all cursor-pointer" />
                </a>
                <p>
                    <MdEmail 
                        className="w-6 h-6 mr-1 text-white hover:opacity-70 opacity-40 transition-all cursor-pointer" 
                        onClick={() => handleEmailClick()}
                    />
                </p>

            </div>

            <p className={`${showEmail ? 'opacity-80' : 'opacity-0'} transition-all duration-500`}>
                {'charltonjones.fj@gmail.com'}
            </p>
        </>
    );
}