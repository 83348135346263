import React, { useState, Suspense } from 'react';

import { Canvas } from '@react-three/fiber'
import { Projects } from './Projects/Projects';
import { Fallback } from './Fallback';
import { Neurons } from './components/Neurons';
// import { Retrowave } from './Retrowave';

export default function App() {
    const isMobile = window.innerWidth < 768;
    const [fontColor, setFontColor] = useState('#7695FF');
    const [showNeurons, setShowNeurons] = useState(true);
    const [neuronsLoaded, setNeuronsLoaded] = useState(isMobile);

    return (
        <Suspense fallback={<Fallback />}>
            <Canvas
                camera={{ position: [0.1, 0.1, 0.1], near: 0.001 }}
            >
                {!isMobile && (
                    <Neurons setNeuronsLoaded={setNeuronsLoaded} />
                )}
            </Canvas>
            {neuronsLoaded && (
                <Projects fontColor={fontColor} showNeurons={showNeurons} setShowNeurons={setShowNeurons} />
            )}
            {/* <Projects fontColor={fontColor} />
            <Canvas>
                <Ring 
                    fontColor={fontColor} 
                    setFontColor={setFontColor}
                />
            </Canvas> */}
        </Suspense>
    );
}