import { OrbitControls } from '@react-three/drei';
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { Bloom, EffectComposer, Glitch } from '@react-three/postprocessing';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';

export const Neurons = ({ setNeuronsLoaded }: { setNeuronsLoaded: (loaded: boolean) => void }) => {
    // const model = useLoader(GLTFLoader, '/neurons/scene.gltf?v=3');
      // Use GLTFLoader and set DRACOLoader
    const model = useLoader(GLTFLoader, '/neurons/scene-draco.gltf', (loader) => {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/'); // Path to the Draco decoder files (JS/wasm)
        loader.setDRACOLoader(dracoLoader);
    });

    const { scene } = useThree();
    scene.fog = new THREE.Fog('#ff00ff', 0.01, 0.9);

    // Check if model loaded successfully
    if (!model) {
        document.body.style.backgroundImage = `url(./images/neurons.png)`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundPosition = 'center';
        document.body.style.backgroundAttachment = 'fixed';
        setNeuronsLoaded(true);
        console.warn('The 3D scene was unable to load. Please try refreshing the page.');
        return null;
    } else {
        // set neurons loaded once model is loaded
        setNeuronsLoaded(true);
        console.log('This work is based on "Neuronal Cell Environment" (https://sketchfab.com/3d-models/neuronal-cell-environment-11fc6dbcc1594e9a806601bb7480f315) by ryannsoup (https://sketchfab.com/ryannsoup) licensed under CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)')
    }


    return (
        <>
            {/* light */}
            <ambientLight intensity={0.5} />
            {/* directional light */}
            <directionalLight position={[2, 2, 2]} intensity={3} color={'red'} />
            <OrbitControls
                makeDefault
                enableZoom={false}
                autoRotate
                autoRotateSpeed={0.5}
            />
            <primitive 
                object={ model.scene } 
                // move along the Z-axis
                position={[0.2, 0.1, 0.2]}
            />

            {/* axis helper */}
            {/* <axesHelper args={[5]} /> */}
            <EffectComposer>
                <Bloom luminanceThreshold={0.1} luminanceSmoothing={0.1} height={300} opacity={1} /> 
            </EffectComposer>
        </>
    )
}