import React, { useEffect, useState } from 'react';
import { getDesaturatedColor } from '../utils';
import { projects, authorInfo } from './projects-data';

import { ProjectInfo } from './ProjectInfo';
import { AuthorInfo } from './AuthorInfo';
import { IconRow } from './IconRow';

export const Projects = ({ fontColor, showNeurons, setShowNeurons }: { fontColor: string, showNeurons?: boolean, setShowNeurons?: any }) => {
    const [hovered, setHovered] = useState<string | null>(null);
    const [showEmail, setShowEmail] = useState<boolean>(false);
    const [desaturatedHexColor, setDesaturatedHexColor] = useState<string>('');
    const isMobile = window.innerWidth < 768;

    useEffect(() => {
        setDesaturatedHexColor(getDesaturatedColor(fontColor));
    }, [fontColor]);

    const handleMouseEnter = (id: string) => {
        if (isMobile) return;
        setHovered(id);
    };

    const handleMouseLeave = () => {
        if (isMobile) return;
        setHovered(null);
    };

    const handleEmailClick = () => {
        if (showEmail) {
            setShowEmail(false);
        } else {
            setShowEmail(true);
            setTimeout(() => {
                setShowEmail(false);
            }, 10000);
        }
    };

    const handleProjectClick = (e: any, id: string) => {
        if (!isMobile) return;
        e.preventDefault();
        setHovered(id);
    }

    return (
        <div className="w-fit h-fit fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center space-y-2 z-50 josefin-sans text-white" >
            <AuthorInfo
                authorInfo={authorInfo}
                hovered={hovered}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                isMobile={isMobile}
                handleProjectClick={handleProjectClick}
                setHovered={setHovered}
            />
            <hr className='w-10 border-slate-500'></hr>

            <div className="flex flex-col items-center space-y-2 text-xl">
                {projects.map((project, index) => (
                    <ProjectInfo 
                        key={index} 
                        project={project} 
                        fontColor={fontColor} 
                        desaturatedHexColor={desaturatedHexColor} 
                        hovered={hovered} 
                        handleMouseEnter={handleMouseEnter} 
                        handleMouseLeave={handleMouseLeave} 
                        handleProjectClick={handleProjectClick} 
                        isMobile={isMobile} 
                        index={index}
                        setHovered={setHovered}
                    />
                ))}
            </div>
            <hr className='w-10 border-slate-500'></hr>
            <IconRow showEmail={showEmail} handleEmailClick={handleEmailClick} />
            {isMobile && (
                <p className='text-xs text-white font-light'>{'Please visit on desktop for the full 3D experience'}</p>
            )}
        </div>
    );
};
